import './Home.style.scss';
import { Helmet } from "react-helmet";

import About from '../About/About.component';
import Skills from '../Skills/Skills.component';
import Work from '../Projects/Projects.component';
import Contact from '../Contact/Contact.component';
import NavigationBar from '../../components/NavigationBar/NavigationBar.component';
import Footer from '../Footer/Footer.component';
import { useEffect } from 'react';

const Home = () => {

    return (
        <div id='home' className='main-container'>
            <Helmet>
                <title>Home | Daniel Gheorghita </title>
            </Helmet>
            <NavigationBar />
            <div className="wrapper">
                <h1 className='maintext'>Hi, it's me Daniel! <br>
                </br>I'm a software developer based in Germany.
                </h1>

            </div>
            <div className='angleshadow'>
                <div className="angle1">
                </div>
            </div>


            <About />
            <Skills />
            <Work />
            <Contact />
            <Footer />
        </div >


    );
}

export default Home;