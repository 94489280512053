import React, { useState } from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import HamburgerMenu from '../HamburgerMenuComponent/HamburgerMenu.component';

import './HamburgerIcon.style.scss';

function HamburgerIcon() {

    const [open, setOpen] = useState(false);

    const HamburgerIcon = <BiMenuAltRight size='45px' color='black' onClick={() => setOpen(!open)} />
    const CloseIcon = <GrClose size='35px' color='black' onClick={() => setOpen(!open)} />

    return (
        <div className="HamburgerMenu-icon">
            <div className='Hamburger'>
                {open ? CloseIcon : HamburgerIcon}
            </div>
            {open && <HamburgerMenu />}
        </div>

    );

}

export default HamburgerIcon;