import React, { useState } from 'react';

import './../Projects/Projects.style.scss';
import HistoryTodayImg from '../../assets/image/1.png';
import TicTacToeImg from '../../assets/image/2.png';
import QuoteImg from '../../assets/image/3.png';
import PongImg from '../../assets/image/4.png';

import Button from '../../components/Button/Button.component';

const Projects = () => {

    return (
        <div id='work' className='projects-container'>
            <h1 data-aos="fade-right" >My Work</h1>

            <div className='container' data-aos-offset="200">
                <div data-aos="fade-left" className='panel'>
                    <img src={HistoryTodayImg} className="img1"></img>
                    <div className='content content1'>
                        <h3>History Today</h3>
                        <p> If you find it cool to be up to date with historical events, HistoryToday offers you the opportunity to find out the most relevant and important events that happened today, on the day you are at the moment. </p>
                        <Button text={"View it here"} active={"deactive"} className="btn" />
                    </div>
                </div>
            </div>

            <div className='container' >
                <div data-aos="fade-right" data-aos-offset="200" className='panel'>
                    <img src={TicTacToeImg} className="img2"></img>
                    <div className='content content2'>
                        <h3>Tic Tac Toe Game</h3>
                        <p>The popular game Tic Tac Toe that we all played when we were little was the programmer's test that everyone tries, both a test of coding and design skills. Try my version if you are melancholic or yearning for older feelings</p>
                        <Button text={"View it here"} active={"deactive"} className="btn"/>
                    </div>
                </div>
            </div>

            <div className='container' >
                <div data-aos="fade-left" data-aos-offset="200" className='panel'>
                    <img src={QuoteImg} className="img3"></img>
                    <div className='content content3'>
                        <h3>Quote Generator</h3>
                        <p>You have inspiration every day with the quote generator with the best quotes of famous people so that they can make you ambitious, motivate or make you happier.</p>
                        <Button text={"View it here"} active={"deactive"} className="btn"/>
                    </div>
                </div>
            </div>

        </div >

    );
}

export default Projects;