import './Button.style.scss';
import { Link } from 'react-scroll';

function Button(props)  {

    return (
        <div className='btn' >
            <button className={`${props.active}`}>
                <Link smooth={true} to={props.link}>{props.text}</Link> 
            </button>
        </div>
    );
}

export default Button;