
import './Skills.style.scss';

const Skills = () => {

    return (
        <div id='skills' className='skills-container'>
            <h1 data-aos="fade-right">My Toolbox & Things I Can Do</h1>
            <div data-aos="fade-up" class="box-container">
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" />
                    <h2>HTML</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" />
                    <h2>CSS</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
                    <h2>JavaScript</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" />
                    <h2>Java</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
                    <h2>React</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" />
                    <h2>Sass</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg" />
                    <h2>MySql</h2>
                </div>
                <div class="box">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" />
                    <h2>Git</h2>
                </div>
                
            </div>

            <div className='angleshadow'>
                <div className="angle angle3">
                </div>
            </div>

        </div >


    );
}

export default Skills;