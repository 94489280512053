import React from 'react';
import './../Contact/Contact.style.scss';
import Instagram from '../../assets/image/instagram.svg';
import Github  from '../../assets/image/github.svg';
import Linkedin  from '../../assets/image/linkedin.svg';
import ContactForm from '../../components/ContactForm/Form.component';


const Contact = () => {

    return (
        <div id='contact' className='contact-container'>
            <h1 data-aos="fade-right" data-aos-offset="200">Contact</h1>
            <div data-aos="fade-up" className='content-container'>
                <ContactForm />
                <hr></hr>
                <div className='social-container'>
                    <ul>
                        <li className='linkedin'><a href='https://www.linkedin.com/in/daniel-gheorghita-607498249/' target="_blank"><img src={Linkedin} alt='linkedin'/></a></li>
                        <li className='github'><a href='https://github.com/dgheorghita' target="_blank"><img src={Github} alt='github' /></a></li>
                        <li className='instagram'><a href='https://www.instagram.com/_daniel.gh_/'><img src={Instagram} alt='instagram'/></a></li>
                    </ul>

                </div>

            </div>
        </div >

    );
}

export default Contact;