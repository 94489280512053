import React from "react";

import Button from "../Button/Button.component";
import './Form.style.scss';

const ContactForm = () => {

    return (
        <div className='form-container'>
            {/*<form action="" method="POST" >
                        <div className='field'>
                            <label className='lable'>Name<span>*</span></label>
                            <input type="text" name='name' placeholder='Your Name' required />
                        </div>
                        <div className='field'>
                            <label className='lable'>Email<span>*</span></label>
                            <input type="text" name='mail' placeholder='Your email' required />
                        </div>
                        <div className='field'>
                            <label className='lable'>Message<span>*</span></label>
                            <textarea name="" id="" cols="20" rows="5" placeholder='Hi, i would like to ...' required></textarea>
                        </div>

                        <Button text='Send' active={'active'} disabled/>

            </form>*/}
            
        </div>
    );
}

export default ContactForm;

