import '../Footer/Footer.style.scss'

const Footer = () => {

    return (
        <div className='footer-container'>
            <div className="content">
                <p>© 2023 | Designed & coded by Daniel Gheorghita</p>
            </div>

        </div >


    );
}

export default Footer;