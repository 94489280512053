import './About.style.scss';


import Button from '../../components/Button/Button.component';
import portret from '../../assets/image/DSC_4505.jpg';

const About = (props) => {

    return (
        <div data-aos="fade-up" id='about' className='about-container'>
            <h1>Who is Daniel?</h1>
            <div className='content'>
                <img src={portret} alt='portret'></img>
                <p>I am a Computer Science student, passionate about bringing both the technical and visual aspects of digital products to life.
                    <span> User experience, beautiful pixels</span> and <span>writing clean code</span> matters to me. I sweat the details and
                    in term of design I strongly support that<span> less is more</span>. I'm happiest when I'm creating, learning, exploring and thinking about how to make things better.
                    <span> If you are working on a project, need consulting or want a collaboration, do not hesitate to contact me.</span>
                    <Button text='Contact me' link='contact' active={'active'}/>
                </p>
                
            </div>
            <div className='angleshadow'>
                <div className="angle angle2"></div>
            </div>

        </div >


    );
}

export default About;