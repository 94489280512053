import React from "react";
import { Link } from 'react-scroll';

import './HamburgerMenu.style.scss';

const HamburgerMenu = (props) => {

    return (
        <div className='HamburgerMenu'>
            <div className="links-container">
                <Link className="nav-link" smooth={true} to='work' >Work</Link>
                <Link className="nav-link" smooth={true} to='skills'>Skills</Link>
                <Link className="nav-link" smooth={true} to='about' >About</Link>
                <Link className="nav-link" smooth={true} to='contact'>Contact</Link>
            </div>
        </div>
    );
}

export default HamburgerMenu;