import './Navigation.style.scss';

import {Link} from 'react-scroll';
import HamburgerIcon from "../HamburgerMenu/HamburgerIcon/HamburgerIcon.component";

const NavigationBar = () => {

    return (

        <div className="Navigation">
            <div className="nav-links-container">
                <Link className="nav-link" smooth={true} to='work' >Work</Link>
                <Link className="nav-link" smooth={true} to='skills' offset={150}>Skills</Link>
                <div className="Logo-container">
                    <Link className="Logo" to='home'>
                        Daniel Gheorghita
                    </Link>
                </div>
                <Link className="nav-link" smooth={true} to='about' offset={-150}>About</Link>
                <Link className="nav-link" smooth={true} to='contact'>Contact</Link>
                <HamburgerIcon />
            </div>
        </div>


    );
}

export default NavigationBar;